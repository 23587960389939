import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const ContactPage = () => (
  <Layout>
    <div style={{color: `red`}}>
      <Link to="/">Home</Link>
      <p>Send us a message</p>
      <h1>I'd love to talk! Email me at the address below</h1>
      <p>
        <a href="mailto:me@example.com">me@example.com</a>
      </p>
    </div>
  </Layout>
)

export default ContactPage